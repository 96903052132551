<template>
  <div>
    <penalties-table :contract="contract"/>
  </div>
</template>

<script>
import penaltiesTable from './components/penaltiesTable'
export default {
  props: {
    contract: {
      type: Object,
    },
  },

  components: {penaltiesTable}
};
</script>
