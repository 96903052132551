<template>
  <div class="row">
    <div class="col-xl-6">
      <div class="table-responsive mb-0">
        <table class="table">
          <tbody>
            <tr>
              <th scope="row">Référence :</th>
              <td>{{ contract.ref }}</td>
            </tr>
            <tr>
              <th scope="row">Prestataire :</th>
              
            </tr>
            <tr>
              <th scope="row">Date de création :</th>
              <td>{{ contract.createdAt }}</td>
            </tr>
            <tr>
              <th scope="row">Date début de validité :</th>
              <td>{{ contract.start_date }}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    <div class="col-xl-6">
      <div class="table-responsive mb-0">
        <table class="table">
          <tbody>
            <tr>
              <th scope="row">Date fin de validité :</th>
              <td>{{ contract.end_date }}</td>
            </tr>
            <tr>
              <th scope="row">Date de signature :</th>
              <td>{{ contract.contract_signature_date }}</td>
            </tr>
            <tr>
              <th scope="row">Date d’entrée en vigueur :</th>
              <td>{{ contract.contract_effective_date }}</td>
            </tr>
            <tr>
              <th scope="row">Status :</th>
              <td><span v-html="contract.status"></span></td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    contract: {
      type: Object,
    },
  },


  data() {
    return {
        // contract: {},
    };
  },
};
</script>
