<template>
  <warranties-table :contract="contract"/>
</template>

<script>
import warrantiesTable from './components/warrantiesTable'
export default {
  props: {
    contract: {
      type: Object,
    },
  },

  components: {warrantiesTable}
};
</script>
